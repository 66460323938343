import React from "react";
import Img from "gatsby-image";
import "./index.css";
import "../style/font-style.css";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import AOS from "aos";
import "aos/dist/aos.css";
import { useStaticQuery, graphql, Link } from "gatsby";
import Head from "../components/head";
import Header from "../components/Header_normal";
import RecentNews from "../components/RecentNews";
import Profile from "../components/Profile";
import Media from "../components/Media";
import Content from "../components/content";
import Footer from "../components/footer";
import Pastevent from "../components/pastevent";
import { Hidden } from "@material-ui/core";
import BoxTypo from "../components/BoxTypo";
import NewsArea from "../components/newsArea";
// import { StoreInfo } from "../components/StoreInfo";
import MediaQuery from "react-responsive";
import Pics from "../components/pics";

export default function Home() {
  if (typeof document !== `undefined`) {
    AOS.init({
      initClassName: "aos-init", // class applied after initialization
      animatedClassName: "aos-animate", // class applied on animation
      disableMutationObserver: false, // disables automatic mutations' detections (advanced)
      delay: 600, // values from 0 to 3000, with step 50ms
      duration: 1000, // values from 0 to 3000, with step 50ms
      easing: "ease", // default easing for AOS animations
      once: false,
      anchorPlacement: "bottom-bottom",
    });
  }
  const data = useStaticQuery(graphql`
    query {
      topImageSp: file(relativePath: { eq: "catervre.png" }) {
        childImageSharp {
          fluid(maxWidth: 2500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      dhiaborittyo: file(relativePath: { eq: "dhiaborittyo.png" }) {
        childImageSharp {
          fluid(maxWidth: 2500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      piccoro: file(relativePath: { eq: "piccoro.png" }) {
        childImageSharp {
          fluid(maxWidth: 2500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      topImagePc: file(relativePath: { eq: "catervre.png" }) {
        childImageSharp {
          fluid(maxWidth: 2500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      dinerImage: file(relativePath: { eq: "diner-picture.png" }) {
        childImageSharp {
          fluid(maxWidth: 2500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      vactorLine: file(relativePath: { eq: "vactorLine.png" }) {
        childImageSharp {
          fluid(maxWidth: 2500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      caterver_foodImg: file(relativePath: { eq: "caterver_foodImg.png" }) {
        childImageSharp {
          fluid(maxWidth: 2500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Catervre: file(relativePath: { eq: "catervre.png" }) {
        childImageSharp {
          fluid(maxWidth: 2500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      HitoyoshiImg: file(relativePath: { eq: "hitoyoshi-img.png" }) {
        childImageSharp {
          fluid(maxWidth: 2500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      FB: file(relativePath: { eq: "FB_logo_mono.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      IG: file(relativePath: { eq: "insta_logo_mono.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ReverceDinerPicture: file(
        relativePath: { eq: "reverce-diner-picture.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      allMicrocmsNews {
        edges {
          node {
            newsId
            title
            date
            content
            image {
              url
            }
          }
        }
      }
    }
  `);

  const companyLists = ["Address", "Tel", "Mail"];
  const companyListsSec = [
    "東京都港区白金6-23-1",
    "03-6875-0618",
    "pivot@piccante.co.jp",
  ];

  return (
    <>
      <Head />
      <Box width="100%" bgcolor="#E5E5E5">
        <Header textColor="#343441" BGColor="#F7F6F4" />
        <Box
          className="fv"
          width="100%"
          margin="auto"
          py={13}
          position="relative"
          bgcolor="#fff"
        >
          <Box margin="auto" width={["50%", "50%", "40%"]} mt={12} mb={5}>
            <Img fluid={data.topImageSp.childImageSharp.fluid} />
          </Box>
          <BoxTypo
            fs={["12px", "14px", "16px"]}
            ls={["1px", "3px"]}
            lh={["140%", "160%"]}
            textAlign={["justify", "center", "center"]}
            width={["90%", "90%", "70%"]}
            maxWidth="1100px"
            mt={3}
            m={"auto"}
          >
            ケータリングは敷居が高い。オードブルでは物足りない。そんなあなたに、ケータリングとオードブルのいいところを合わせました。
            Catervre（ケータブル）はご希望の納品時刻の30分前にお届けし、最後の仕上げを現地で行う新しいスタイルのサービスです。最後の仕上げにキッチンの有無は関係ありません。３０分間のプロの技術によって、​お手軽にケータリングの雰囲気も味わえます。
          </BoxTypo>
          <Box textAlign="center">
            <BoxTypo fs={["24px", "36px", "48px"]} mt={[5, 10]} fw="700" ls="0">
              “Our service.
            </BoxTypo>
            <BoxTypo fs="14px">サービス内容</BoxTypo>
            <Box
              mt={5}
              maxWidth="1200px"
              width={["95%", "95%", "90%"]}
              m={"auto"}
            >
              <Grid container>
                <Grid item xs={12} sm={6}>
                  <BoxTypo
                    fs={["18px", "18px", "24px"]}
                    ls={["1px", "3px"]}
                    lh={["180%", "200%"]}
                    textAlign="center"
                    width="100%"
                    // maxWidth="1100px"
                    mt={3}
                    fw="700"
                    m={"auto"}
                  >
                    Casual plan
                  </BoxTypo>
                  <Box
                    width="90%"
                    maxWidth="800px"
                    m={"auto"}
                    bgcolor="#E2D9D0"
                  >
                    <Box
                      width="90%"
                      pt={[3, 8, 8]}
                      style={{
                        margin: "auto",
                        maxWidth: "500px",
                        marginBottom: "8px",
                        textAlign: "Left",
                      }}
                    >
                      <BoxTypo fs="14px">・３種のブルスケッタ</BoxTypo>
                      <BoxTypo fs="14px">・フリッタータ</BoxTypo>
                      <BoxTypo fs="14px">・シーザーサラダ</BoxTypo>
                      <BoxTypo fs="14px">・鮮魚のカルパッチョ</BoxTypo>
                      <BoxTypo fs="14px">・ハムサラミ盛り合わせ</BoxTypo>
                      <BoxTypo fs="14px">・牛肉のラグーソースペンネ</BoxTypo>
                      <BoxTypo fs="14px" pb={[5, 10, 10]}>
                        ・ローストポーク
                      </BoxTypo>
                    </Box>
                    <BoxTypo
                      fs="12px"
                      textAlign="right"
                      style={{
                        width: "90%",
                        margin: "auto",
                        marginTop: "32px",
                        maxWidth: "500px",
                        paddingBottom: "32px",
                      }}
                    >
                      ¥3000円(税込)/名
                    </BoxTypo>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <BoxTypo
                    fs={["18px", "18px", "24px"]}
                    ls={["1px", "3px"]}
                    lh={["180%", "200%"]}
                    textAlign="center"
                    width="100%"
                    // maxWidth="1100px"
                    mt={3}
                    fw="700"
                    m={"auto"}
                  >
                    Special plan
                  </BoxTypo>
                  <Box
                    width="90%"
                    maxWidth="800px"
                    m={"auto"}
                    bgcolor="#E2D9D0"
                  >
                    <Box
                      width="90%"
                      pt={[3, 8, 8]}
                      style={{
                        margin: "auto",
                        maxWidth: "500px",
                        marginBottom: "8px",
                        textAlign: "Left",
                      }}
                    >
                      <BoxTypo fs="14px">
                        ・トマトのガスパチョor季節のスープ
                      </BoxTypo>
                      <BoxTypo fs="14px">
                        ・３種のブルスケッタorカチャトーラ
                      </BoxTypo>
                      <BoxTypo fs="14px">・シーザーサラダ</BoxTypo>
                      <BoxTypo fs="14px">・シーザーサラダ</BoxTypo>
                      <BoxTypo fs="14px">・鮮魚のカルパッチョ</BoxTypo>
                      <BoxTypo fs="14px">・ハムサラミ盛り合わせ</BoxTypo>
                      <BoxTypo fs="14px">・海老のジェノベーセニョッキ</BoxTypo>
                      <BoxTypo fs="14px">・ローストポーク</BoxTypo>
                      <BoxTypo fs="14px">
                        ​・牛肉のタリアータor牛ほほ肉の赤ワイン煮
                      </BoxTypo>
                      <BoxTypo fs="14px" pb={[5, 10, 10]}>
                        ・チョコラータor季節のタルト
                      </BoxTypo>
                    </Box>
                    <BoxTypo
                      fs="12px"
                      textAlign="right"
                      style={{
                        width: "90%",
                        margin: "auto",
                        marginTop: "32px",
                        maxWidth: "500px",
                        paddingBottom: "32px",
                      }}
                    >
                      ¥5000円(税込)/名
                    </BoxTypo>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Box>
            <Box m="50px" textAlign="center">
              <BoxTypo fs={["24px", "36px", "48px"]} ls="0" fw="700">
                “Pictures .
              </BoxTypo>
              <BoxTypo fs="14px">お料理の様子</BoxTypo>
            </Box>
            <Hidden smUp>
              <Box margin="auto" width="100%" pb={4}>
                <Img fluid={data.caterver_foodImg.childImageSharp.fluid} />
              </Box>
            </Hidden>
            <Hidden xsDown>
              <Box margin="auto" width="75%" pb={4}>
                <Img fluid={data.caterver_foodImg.childImageSharp.fluid} />
              </Box>
            </Hidden>
            {/* <Pics /> */}
          </Box>
          <Box width="25%" my={5} margin="auto" textAlign="center">
            <Img fluid={data.vactorLine.childImageSharp.fluid} />
          </Box>
          <Box margin="auto" width={["90%", "90%", "70%"]} py={[2, 5, 10]}>
            <BoxTypo
              textAlign="center"
              fs={["24px", "36px", "48px"]}
              ls="0"
              fw="700"
            >
              “Option .
            </BoxTypo>
            <BoxTypo textAlign="center" fs="14px">
              オプション
            </BoxTypo>
            <BoxTypo fs="16px" mt={3}>
              ・ドリンク購入代行
            </BoxTypo>
            <BoxTypo fs="14px" mt={3} margin="auto" width="95%">
              ドリンクのご購入金額(税込)の15％で購入代行の承っております。会場までお届けいたします。詳細はお尋ねください。
            </BoxTypo>
            <BoxTypo fs="16px" mt={3}>
              ・パーティーセット
            </BoxTypo>
            <BoxTypo fs="14px" mt={3} margin="auto" width="95%">
              ​取り皿・コップ・割り箸をセットにし、10セット1,000円(税別)〜ご用意いたします。{" "}
            </BoxTypo>
          </Box>
          <Box margin="auto" width={["90%", "90%", "70%"]} py={[2, 5, 10]}>
            <BoxTypo
              textAlign="center"
              ls="0"
              fs={["24px", "36px", "48px"]}
              fw="700"
            >
              “Detail .
            </BoxTypo>
            <BoxTypo textAlign="center" fs="14px" mb={[3, 8, 8]}>
              詳細
            </BoxTypo>

            <Box
              maxWidth="1200px"
              width={["95%", "90%", "90%"]}
              m={"auto"}
              bgcolor="#E2D9D0"
            >
              <Grid container>
                <Grid item xs={12} sm={6}>
                  <Box m={"auto"}>
                    <Box
                      pt={4}
                      style={{
                        width: "80%",
                        margin: "auto",
                        // paddingTop: "64px",
                        // maxWidth: "500px",
                        marginBottom: "8px",
                        textAlign: "Left",
                      }}
                    >
                      {/* <Hidden smDown>
                        <BoxTypo my={2} fs="14px" fw="700">
                          サービスエリア
                        </BoxTypo>

                        <BoxTypo fs="14px" lh={["280%", "200%"]}>
                          港区、渋谷区、目黒区、品川区
                          <br />
                          配送料について <br />
                          別途2000円(税別)の配送料が発生いたします
                          <BoxTypo my={2} fs="14px" fw="700">
                            営業時間
                          </BoxTypo>
                          １１：００〜２２：００
                        </BoxTypo>
                        <BoxTypo fs="14px" pb={[3, 3, 5]}>
                          （時間外は要相談）
                        </BoxTypo>
                      </Hidden>
                      <Hidden mdUp> */}
                      <BoxTypo my={2} fs="14px" fw="700">
                        サービスエリア
                      </BoxTypo>
                      <BoxTypo
                        fs="14px"
                        textAlign="justify"
                        lh={["280%", "200%"]}
                      >
                        港区、渋谷区、目黒区、品川区
                      </BoxTypo>
                      <BoxTypo my={2} fs="14px" fw="700">
                        配送料について
                      </BoxTypo>
                      <BoxTypo
                        fs="14px"
                        textAlign="justify"
                        lh={["280%", "200%"]}
                      >
                        別途2000円(税別)の配送料が発生いたします
                      </BoxTypo>
                      <BoxTypo my={2} fs="14px" fw="700">
                        営業時間
                      </BoxTypo>
                      １１：００〜２２：００
                      <BoxTypo fs="14px" pb={[3, 3, 5]}>
                        （時間外は要相談）
                      </BoxTypo>
                      {/* </Hidden> */}
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box m={"auto"}>
                    {/* <Hidden smDown>
                      <Box
                        pt={4}
                        style={{
                          width: "80%",
                          margin: "auto",
                          // paddingTop: "64px",
                          // maxWidth: "500px",
                          marginBottom: "8px",
                          textAlign: "Left",
                        }}
                      >
                        <BoxTypo my={2} fs="14px" fw="700">
                          ご注文方法
                        </BoxTypo>
                        <BoxTypo fs="14px" lh={["280%", "200%"]}>
                          お届け希望日の３日前までに
                          <br />
                          電話またはメールにてご連絡ください。 <br />
                          TEL: 090-5173-5108 <br />
                          MAIL:CV@piccante.co.jp
                        </BoxTypo>
                      </Box>
                    </Hidden> */}
                    {/* <Hidden mdUp> */}
                    <Box
                      pt={4}
                      style={{
                        width: "80%",
                        margin: "auto",
                        // paddingTop: "64px",
                        // maxWidth: "500px",
                        marginBottom: "8px",
                        textAlign: "Left",
                      }}
                    >
                      <BoxTypo my={2} fs="14px" fw="700">
                        ご注文方法
                      </BoxTypo>
                      <BoxTypo
                        fs="14px"
                        textAlign="justify"
                        lh={["280%", "200%"]}
                      >
                        お届け希望日の３日前までに
                        電話またはメールにてご連絡ください。
                      </BoxTypo>
                      <br />
                      TEL: 03-6875-0618
                      <br />
                      MAIL:CV@piccante.co.jp
                    </Box>
                    {/* </Hidden> */}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Box margin="auto" width={["90%", "90%", "70%"]} py={[2, 5, 10]}>
            <BoxTypo
              textAlign="center"
              ls="0"
              fs={["24px", "36px", "48px"]}
              fw="700"
            >
              “Payment .
            </BoxTypo>
            <BoxTypo textAlign="center" fs="14px" mb={8}>
              お支払い方法
            </BoxTypo>
            <BoxTypo textAlign="center" fs="14px" mb={8}>
              当日現金 or クレジットカード
              or電子マネーで、お支払いいただけます。
              <br />
              また振込でのお支払いも可能です。
            </BoxTypo>
            <BoxTypo textAlign="center" fs="16px" mb={4}>
              クレジットカード対応ブランド
            </BoxTypo>
            <BoxTypo textAlign="center" fs="14px" mb={8}>
              JCB、VISA、MasterCard,
              <br />
              AMERICANEXPRESS、Diners
            </BoxTypo>
            <BoxTypo textAlign="center" fs="16px" mb={4}>
              電子マネー対応ブランド
            </BoxTypo>
            <BoxTypo textAlign="center" fs="14px" mb={8}>
              ​楽天Edy、Suica、PASMO、GooglePay、ApplePay
            </BoxTypo>
          </Box>
          <Box margin="auto" width={["90%", "90%", "70%"]} py={[2, 5, 10]}>
            <BoxTypo
              textAlign="center"
              ls="0"
              fs={["24px", "36px", "48px"]}
              fw="700"
            >
              “Cancellation Policy .
            </BoxTypo>
            <BoxTypo textAlign="center" fs="14px" mb={8}>
              キャンセルポリシー
            </BoxTypo>
            <BoxTypo textAlign="center" fs="14px" mb={8}>
              ご注文の変更・キャンセルはご利用予定日の5日前まで承ります。
              それ以降のキャンセルにつきましては、以下のキャンセル料が発生します。
            </BoxTypo>
            <BoxTypo
              margin="auto"
              fs={["12px", "14px", "14px"]}
              mb={8}
              lh={["200%", "280%"]}
              textAlign="center"
            >
              ご利用予定日の４日前・・・お見積金額の３０％
              <br />
              ご利用予定日の２日前・・・お見積金額の５０％ <br />
              ​ご利用予定日当日・・・お見積金額の１００％　
            </BoxTypo>
          </Box>
          <Box width="100%" bgcolor="#b8a07c" marginTop="64px">
            <BoxTypo
              color="#fff"
              fs={["24px", "36px", "48px"]}
              width={["90%", "70%", "60%"]}
              m={"auto"}
              ls="0"
              fw="bold"
            >
              “Store info.
            </BoxTypo>
            <BoxTypo
              color="#fff"
              fs={["11px", "12px", "14px"]}
              width={["90%", "70%", "55%"]}
              m={"auto"}
              fw="900"
              ls="10px"
            >
              店舗情報
            </BoxTypo>
          </Box>
          <Box
            display={["block", "block", "block", "flex"]}
            width={["95%", "90%", "70%"]}
            m={"auto"}
            py={["40px", "50px", "60px"]}
          >
            <Box
              display="flex"
              justifyContent="center"
              width={["100%", "100%", "100%", "70%"]}
              py={5}
            >
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-around"
              >
                {companyLists.map((list, index) => (
                  <BoxTypo key={index} fs={["12px", "16px", "18px"]} lh="2.5">
                    {list}
                  </BoxTypo>
                ))}
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-around"
                pl={["20px", "30px", "40px"]}
              >
                {companyListsSec.map((list, index) => (
                  <BoxTypo key={index} fs={["12px", "16px", "18px"]} lh="2.5">
                    {list}
                  </BoxTypo>
                ))}
              </Box>
            </Box>
            <Hidden lgUp>
              <BoxTypo
                width={["90%", "50%", "50%"]}
                m={"auto"}
                fs={["8px", "9px", "11px"]}
                pb={5}
              >
                ※ 系列店TRIPPERIA IL MACAONEの電話番号になります。
                <br />
                ケータブルの注文をしたいとお伝えください。
              </BoxTypo>
            </Hidden>
            <MediaQuery query="(max-width: 768px)">
              <Box>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3242.324022408544!2d139.71927811514388!3d35.64438768020293!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188b11e38775e5%3A0x3c7e4da6cfb3d98b!2z44CSMTA4LTAwNzIg5p2x5Lqs6YO95riv5Yy655m96YeR77yW5LiB55uu77yS77yT4oiS77yR!5e0!3m2!1sja!2sjp!4v1626344166722!5m2!1sja!2sjp"
                  width="100%"
                  height="300"
                  style={{ border: "0" }}
                  allowfullscreen=""
                  loading="lazy"
                ></iframe>
              </Box>
            </MediaQuery>

            <MediaQuery query="(min-width: 769px)">
              <Box display="flex" justifyContent="center">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3242.324022408544!2d139.71927811514388!3d35.64438768020293!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188b11e38775e5%3A0x3c7e4da6cfb3d98b!2z44CSMTA4LTAwNzIg5p2x5Lqs6YO95riv5Yy655m96YeR77yW5LiB55uu77yS77yT4oiS77yR!5e0!3m2!1sja!2sjp!4v1626344166722!5m2!1sja!2sjp"
                  width="500"
                  height="300"
                  style={{ border: "0" }}
                  allowfullscreen=""
                  loading="lazy"
                ></iframe>
              </Box>
            </MediaQuery>
          </Box>
          <Hidden mdDown>
            <BoxTypo
              width={["95%", "90%", "60%"]}
              m={"auto"}
              fs={["8px", "9px", "11px"]}
              pb={5}
            >
              ※ 系列店TRIPPERIA IL MACAONEの電話番号になります。
              <br />
              ケータブルの注文をしたいとお伝えください。
            </BoxTypo>
          </Hidden>
          <Box
            width="500px"
            maxWidth="300px"
            m={"auto"}
            display="flex"
            justifyContent="space-around"
          >
            <Link
              to="https://instagram.com/catervre?utm_medium=copy_link"
              target="_blank"
              rel="noopener noreferrer"
              width="100px"
            >
              <Img
                fluid={data.IG.childImageSharp.fluid}
                style={{ width: "60px", height: "auto" }}
              />
            </Link>
            {/* <Link
              to="https://ja-jp.facebook.com/macaone.platino"
              target="_blank"
              rel="noopener noreferrer"
              width="300px"
            >
              <Img
                fluid={data.FB.childImageSharp.fluid}
                style={{ width: "60px", height: "auto" }}
              />
            </Link> */}
          </Box>
        </Box>
        {/* 2ページ目 */}

        <Footer textColor="#FFFFFF" BGColor="#343441" />
      </Box>
    </>
  );
}
